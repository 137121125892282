import React from "react";
import ContentLoader from "react-content-loader";
const newWidth = window.innerWidth * 1;
export const HelpLinksLoader = (props) => (
  <ContentLoader
    width={newWidth}
    height={350}
    viewBox={`0 0 ${newWidth} 350`}
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="0.57%" y="8" rx="3" ry="3" width="1.143%" height="317" />
    <rect x="1%" y="317" rx="3" ry="3" width="95.57%" height="8" />
    <rect x="95.57%" y="9" rx="3" ry="3" width="1%" height="313" />
    <rect x="0.71%" y="8" rx="3" ry="3" width="95.57%" height="7" />
    <rect x="16.29%" y="52" rx="6" ry="6" width="69%" height="15" />
    <circle cx="77" cy="60" r="15" />
    <rect x="16.6%" y="105" rx="6" ry="6" width="60%" height="15" />
    <circle cx="78" cy="113" r="15" />
    <rect x="16.43%" y="158" rx="6" ry="6" width="69%" height="15" />
    <circle cx="78" cy="166" r="15" />
    <rect x="16.71%" y="211" rx="6" ry="6" width="63.43%" height="15" />
    <circle cx="79" cy="219" r="15" />
    <rect x="16.71%" y="263" rx="6" ry="6" width="69%" height="15" />
    <circle cx="80" cy="271" r="15" />
  </ContentLoader>
);
