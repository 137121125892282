import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";

import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import {
  getReportsData,
  getCommissionReports,
  listAgentsSuper,
  listClientsSuper,
  listCompleteOrders,
} from "./../../../config/api_calls";

import { dateString } from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat, { masks } from "dateformat";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const extendedMoment = extendMoment(moment);

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Reports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();

    var datestring = new Date().toISOString().slice(0, 10);

    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      tableLoader: true,
      start_date: monthAgo.format("YYYY-MM-DD"),
      end_date: today.format("YYYY-MM-DD"),
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: null,
      value: moment.range(monthAgo, today),
      commission_title_array: "get_commission_reports",
      commissionReportsArray: [],
      all_active_agents: [],
      all_active_clients: [],
      all_complete_orders: [],
      columnsArray: [
        {
          name: "order_created_date",
          label: "Order Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "orderno",
          label: "Order #",
        },
        {
          name: "agent_name",
          label: "CP Name",
        },
        {
          name: "agent_company",
          label: "CP Company",
        },
        {
          name: "client_name",
          label: "Client Name",
        },
        {
          name: "client_company",
          label: "Client Company",
        },
        {
          name: "order_total",
          label: "Order Total",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        /*{
          name: "total_payments",
          label: "Order Total Paid",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },*/
        {
          name: "payment_status",
          label: "Order Payment",
        },
        {
          name: "order_commission",
          label: "Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "total_paid_commissions",
          label: "Paid Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "commission_paid",
          label: "Commission Paid",
        },
      ],
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    await this.getActiveAgents();
    await this.getActiveClients();
    await this.getCompleteOrders();
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      this.state.start_date,
      this.state.end_date,
      this.state.selected_order_accountno,
      this.state.selected_client_accountno,
      this.state.selected_agent_accountno
    );
    console.log("getCommissionReports: ", reportsData.data);
    console.log("start_date: ", this.state.start_date);
    console.log("end_date: ", this.state.end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  regenerate_report = async () => {
    const {
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno,
      selected_agent_accountno,
    } = this.state;
    $("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ tableLoader: true });
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno,
      selected_agent_accountno
    );
    console.log("getCommissionReports regenerate_report: ", reportsData.data);
    console.log("start_date: ", start_date);
    console.log("end_date: ", end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      $("#viewBtn").show();
      $("#btnloader").hide();
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    }
  };

  getActiveAgents = async () => {
    const servicesResponce = await listAgentsSuper(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getActiveAgents: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][0] +
                " (" +
                servicesResponce.data.data[i][1] +
                ")",
              value: servicesResponce.data.data[i][4],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_agents: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getActiveClients = async () => {
    const servicesResponce = await listClientsSuper(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][0] +
                " (" +
                servicesResponce.data.data[i][1] +
                ")",
              value: servicesResponce.data.data[i][4],
              agent_accountno: servicesResponce.data.data[i][6],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_clients: all_clients,
        filtered_clients: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getCompleteOrders = async () => {
    const servicesResponce = await listCompleteOrders(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getCompleteOrders: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][0] +
              " ($" +
              servicesResponce.data.data[i][2] +
              ")",
            value: servicesResponce.data.data[i][0],
            client_accountno: servicesResponce.data.data[i][1],
            agent_accountno: servicesResponce.data.data[i][3],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_complete_orders: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      const selected_agent_accountno = changed_value.value;
      this.setState(
        (prevState) => ({
          selected_agent_accountno,
          selected_agent: changed_value,
          selected_client: null,
          selected_order: null,
          filtered_clients: prevState.all_active_clients.filter(
            (client) => client.agent_accountno === selected_agent_accountno
          ),
          filtered_orders: prevState.all_complete_orders.filter(
            (order) => order.agent_accountno === selected_agent_accountno
          ),
        }),
        () => {
          console.log(
            "Updated agent state:",
            this.state.selected_agent_accountno
          );
          console.log("Client selection reset.");
        }
      );
    } else {
      this.setState({
        selected_agent: null,
        selected_client: null,
        selected_order: null,
        selected_agent_accountno: null,
        filtered_clients: this.state.all_active_clients,
        filtered_orders: this.state.all_complete_orders,
      });
    }
  };

  handleChangeSearchClient = async (changed_value) => {
    console.log("handleChangeSearchClient: ", changed_value);
    if (changed_value) {
      const selected_client_accountno = changed_value.value;
      this.setState(
        (prevState) => ({
          selected_client_accountno: changed_value.value,
          selected_client: changed_value,
          selected_order: null,
          filtered_orders: prevState.all_complete_orders.filter(
            (order) =>
              order.agent_accountno === prevState.selected_agent_accountno &&
              order.client_accountno === selected_client_accountno
          ),
        }),
        () => {
          console.log(
            "Updated client state:",
            this.state.selected_client_accountno
          );
        }
      );
    } else {
      this.setState({
        selected_client: null,
        selected_order: null,
        selected_client_accountno: null,
        filtered_orders: this.state.all_complete_orders.filter(
          (order) =>
            order.agent_accountno === this.state.selected_agent_accountno
        ),
      });
    }
  };

  handleChangeSearchOrders = async (changed_value) => {
    console.log("handleChangeSearchOrders: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          selected_order_accountno: changed_value.value,
          selected_order: changed_value,
        }),
        () => {
          console.log(
            "Updated order state:",
            this.state.selected_order_accountno
          );
        }
      );
    } else {
      this.setState({ selected_order: null, selected_order_accountno: null });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  formSave = async () => {
    let { start_date, end_date } = this.state;
    await this.handleStates(start_date, end_date);
    $("#viewBtn").hide();
    $("#btnloader").show();
    await this.regenerate_report();
  };

  capitalizeFirstLetters = (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: "+  typeof amount + " - " + amount);
    //console.log("amount: ", typeof amount);
    if (!amount) {
      amount = 0;
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.decimalPoint,
      maximumFractionDigits: this.decimalPoint,
    });
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = `${start_date} - ${end_date}`;
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitle name="Commission Reports" icon="icon ni ni-reports" />
              <div className="row align-items-end g-4">
                <div className="col-lg-3 col-xxl-3">
                  <div onClick={this.onToggle} className="form-group">
                    <TextField
                      label="Date Range"
                      variant="outlined"
                      fullWidth
                      value={formattedDateRange}
                      InputProps={{
                        onClick: this.onToggle,
                      }}
                      placeholder="Select Date Range"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.all_active_agents}
                      name="selected_accountno"
                      placeholder="Select Agents"
                      value={this.state.selected_agent}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearch}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_clients}
                      name="selected_accountno"
                      placeholder="Select Clients"
                      value={this.state.selected_client}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchClient}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_orders}
                      name="selected_accountno"
                      placeholder="Select Orders"
                      value={this.state.selected_order}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchOrders}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-4" style={{ display: "block" }}>
                <div className="text-right mt-2 mb-2">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary px-4"
                    onClick={() => {
                      this.regenerate_report();
                    }}
                    disabled={this.state.disabled}
                  >
                    <em class="icon ni ni-search"></em>
                    <span>Apply</span>
                  </button>
                  <button
                    id="btnloader"
                    class="btn btn-lg btn-primary px-4"
                    type="button"
                    style={{ display: "none" }}
                    disabled="disabled"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </button>
                </div>
              </div>
              {isOpen && (
                <div
                  className="fixed inset-0"
                  style={{
                    zIndex: 1000,
                    position: "absolute",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="absolute inset-0"
                    onClick={this.onToggle}
                  ></div>
                  <div
                    className="absolute top-40 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4"
                    style={{ zIndex: 1001 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                        zIndex: 1002,
                      }}
                      onClick={this.onToggle}
                    >
                      <em className="icon ni ni-cross"></em>
                    </div>
                    <DateRangePicker
                      value={value}
                      onSelect={this.onSelect}
                      singleDateRange={true}
                      numberOfCalendars={2}
                      showLegend={true}
                    />
                  </div>
                </div>
              )}
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          <DataTable
                            columns={this.state.columnsArray}
                            tableData={this.state.commissionReportsArray}
                            title=""
                          />
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
