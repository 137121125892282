import { React, createRef } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
  getSmtpSettings,
  addSmtpSettings,
  getPaymentSettings,
  addPaymentSettings,
  verifySmtpSettings,
  getQuotationSettings,
  addQuotationSettings,
  getCommissionSettings,
  addCommissionSettings,
  getClientNotes,
  editClientNotes,
  getSignatorySettings,
  deleteSignatorySettings,
  getRFQSettings,
  editRfqSettings,
  getSignatorySettingsByID,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
} from "./../../../config/config";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import PageTitle from "../ExtraComponents/PageTitle";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import Link from "@material-ui/core/Link";
//import { Editor } from "@tinymce/tinymce-react";
import dateFormat, { masks } from "dateformat";
import Select from "react-select";
import makeAnimated from "react-select/animated";
//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";

import { ValidateEmail } from "./../../../config/utility";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import countryList from "react-select-country-list";

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Upgrades extends Component {
  constructor() {
    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      payment_reg_button: false,
      quotation_button: false,
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commissionErrorTitle: "",
      commission_button: false,
      commission_type: "paid_on_collected",
      commission_value_type: "percentage",
      apply_commission: "agent_account",
      draw_threshold: (0).toFixed(this.decimalPoint),
      commission_value: "",
      bank_account_details: "",
      wire_transfer_details: "",
      quotation_notes: "",
      payment_terms: "",
      selected_payment_terms: null,
      currency: "",
      decimalPoint: 2,
      selected_currency: null,
      contact_person: "",
      contact_email: "",
      validity: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderNotes: false,
      tableLoaderRfq: false,
      client_rfq_button: false,
      tableLoaderPayment: false,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoaderQuote: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      notifications_email: "",
      billing_email: "",
      sales_email: "",
      settings_type: "",
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
      //Signatory Settings
      signatory_name: "",
      signatory_company: "",
      signatory_title: "",
      signatory_signature_image: "",
      signatory_status: "",
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingErrorTitle: "",
      signatorySettingNotFound: "",
      signatory_button: false,
      signatoryDetails: [],
      columnsignatoryDetails: [],
      add_picture: "",
      add_picture_name: "",
      add_picture_update: "",
      add_picture_name_update: "",
      signatory_button_delete: false,
      signatory_id: "",
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: false,
      isEditorReady: false,
      //RFQ Settings
      rfqSettingTitle: "",
      rfqSettingHeading: "",
      rfqSettingDesc: "",
      rfqSettingSuccessMessage: "",
      rfqSettingErrorMessage: "",
      rfq_setting_button: false,
      //Cropper
      brightness: 100,
      showBrightnessRange: false,
      add_picture_existing: "",
      loading: false,

      signatory_email: "",
      signatory_phone: "",
      signatory_address: "",
      signatory_address2: "",
      signatory_city: "",
      signatory_zip: "",
      signatory_state: "",
      signatory_country: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
      isLoading: true,
      isFileUploaded: false,
    };
    this.cropperRef = createRef();
    this.phoneInputRef = createRef();
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
  }

  async componentDidMount() {
    //settings_type
    let settings_type = this.props.props.match.params.settings_type;
    console.log("settings_type: ", settings_type);
    this.setState({
      settings_type: settings_type,
    });
    if (settings_type === "smtp_settings") {
      this.get_smtp_settings();
    }
    if (settings_type === "payment_settings") {
      this.get_payment_settings();
    }
    if (settings_type === "quotation_settings") {
      this.get_quotation_settings();
    }
    if (settings_type === "commission_settings") {
      this.get_commission_settings();
    }
    if (settings_type === "other_settings") {
      this.get_other_settings();
    }
    if (settings_type === "signatory_settings") {
      this.get_signatory_settings();
    }
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  get_smtp_settings = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getSmtpSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("get_smtp_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        smtp_host: servicesResponce.data.data[0].smtp_host,
        smtp_user: servicesResponce.data.data[0].smtp_user,
        smtp_password: servicesResponce.data.data[0].smtp_password,
        smtp_method: servicesResponce.data.data[0].smtp_method,
        smtp_port: servicesResponce.data.data[0].smtp_port,
        from_email: servicesResponce.data.data[0].from_email,
        notifications_email: servicesResponce.data.data[0].notifications_email,
        is_working: servicesResponce.data.data[0].is_working,
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "no_record"
    ) {
      this.setState({
        smtp_host: "",
        smtp_user: "",
        smtp_password: "",
        smtp_method: "tls",
        smtp_port: "",
        from_email: "",
        notifications_email: "",
        is_working: "no",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
      notifications_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    }
    if (notifications_email === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Notifications email is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (notifications_email !== "") {
      if (this.validateEmailAdd(notifications_email) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid notifications email format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (is_validated) {
      const servicesResponce = await addSmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email,
        notifications_email
      );
      //console.log("addSmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings added successfully.",
          smtp_reg_button: false,
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  //Test SMTP Settings

  verify_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_test_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await verifySmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      console.log("verifySmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings tested successfully.",
          smtp_test_button: false,
          is_working: "yes",
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  get_payment_settings = async () => {
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      tableLoaderPayment: true,
    });
    const servicesResponce = await getPaymentSettings(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("get_payment_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
        tableLoaderPayment: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        isEditorReady: true,
        bank_account_details: servicesResponce.data.bank_details,
        wire_transfer_details: servicesResponce.data.wire_transfer_details,

        tableLoaderPayment: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        isEditorReady: true,
        bank_account_details: "",
        wire_transfer_details: "",
        tableLoaderPayment: false,
      });
    } else {
      this.setState({
        paymentErrorMessage:
          "There is some error while getting payment settings.",
        paymentSuccessMessage: "",
        tableLoaderPayment: false,
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_payment_settings = async () => {
    let { bank_account_details, wire_transfer_details } = this.state;
    this.setState({
      paymentErrorMessage: "",
      paymentSuccessMessage: "",
      payment_reg_button: true,
    });
    var is_validated = true;

    if (bank_account_details === "") {
      this.setState({
        paymentErrorMessage: "Payment details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }
    /*if (wire_transfer_details === "") {
      this.setState({
        paymentErrorMessage: "Wire transfer details are required.",
        payment_reg_button: false,
      });
      is_validated = false;
    }*/

    if (is_validated) {
      const servicesResponce = await addPaymentSettings(
        auth.getAccount(),
        auth.getToken(),
        bank_account_details,
        wire_transfer_details
      );
      console.log("add_payment_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          paymentErrorMessage: "",
          paymentSuccessMessage: "Payment settings added successfully.",
          payment_reg_button: false,
        });
      } else {
        this.setState({
          paymentErrorMessage:
            "There is some error while adding payment settings.",
          paymentSuccessMessage: "",
          payment_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ paymentErrorMessage: "", paymentSuccessMessage: "" });
    }, 5000);
  };

  get_other_settings = async () => {
    this.setState({
      clientNotes: "",
      otherSettingSuccessMessage: "",
      otherSettingErrorMessage: "",
      client_setting_button: false,
      tableLoaderNotes: true,
    });
    const notesResponce = await getClientNotes(
      auth.getAccount(),
      auth.getToken()
    );

    //console.log("Client Notes  Res: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        isEditorReady: true,
        clientNotes: notesResponce.data.data.welcome_content,
        tableLoaderNotes: false,
        client_setting_button: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  get_rfq_settings = async () => {
    this.setState({
      rfqSettingSuccessMessage: "",
      rfqSettingErrorMessage: "",
      rfq_setting_button: false,
      tableLoaderRfq: true,
    });
    const notesResponce = await getRFQSettings(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("get_rfq_settings  Res: ", notesResponce.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        rfqSettingTitle: notesResponce.data.data.title,
        rfqSettingHeading: notesResponce.data.data.heading,
        rfqSettingDesc: notesResponce.data.data.short_desc,
        tableLoaderRfq: false,
        rfq_setting_button: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  saveRfqSettings = async () => {
    window.scrollTo(0, 0);

    const { rfqSettingTitle, rfqSettingHeading, rfqSettingDesc } = this.state;
    var validated = true;
    if (rfqSettingTitle === "") {
      validated = false;
    } else if (rfqSettingHeading === "") {
      validated = false;
    } else if (rfqSettingDesc === "") {
      validated = false;
    } else {
      validated = true;
    }
    if (validated === false) {
      return this.setState({
        rfqSettingErrorMessage: "Please enter the required field.",
        rfqSettingSuccessMessage: "",
      });
    } else {
      console.log(
        "saveRfqSettings Fields: " +
          rfqSettingTitle +
          " " +
          rfqSettingHeading +
          " " +
          rfqSettingDesc
      );
      this.setState({
        rfqSettingErrorMessage: "",
        rfqSettingSuccessMessage: "",
        disabled: true,
        rfq_setting_button: true,
      });

      const saveResponce = await editRfqSettings(
        auth.getAccount(),
        auth.getToken(),
        rfqSettingTitle,
        rfqSettingHeading,
        rfqSettingDesc
      );
      console.log("editRfqSettings: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          rfqSettingErrorMessage:
            "There is some error while updating the RFQ settings.",
          rfqSettingSuccessMessage: "",
          disabled: false,
          rfq_setting_button: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          rfqSettingErrorMessage: "",
          rfqSettingSuccessMessage: "RFQ settings updated successfully.",
          disabled: false,
          rfq_setting_button: false,
        });
        setTimeout(() => {
          this.setState({
            rfqSettingErrorMessage: "",
            rfqSettingSuccessMessage: "",
            disabled: false,
            rfq_setting_button: false,
          });
          this.get_rfq_settings();
        }, 3000);
      } else {
        this.setState({
          rfqSettingErrorMessage: saveResponce.data.message,
          rfqSettingSuccessMessage: "",
          disabled: false,
          rfq_setting_button: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  saveClientNotes = async () => {
    window.scrollTo(0, 0);
    const clientNotes = this.state.clientNotes;
    var validated = true;
    if (clientNotes === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        otherSettingErrorMessage: "Please enter the required field.",
        otherSettingSuccessMessage: "",
      });
    } else {
      this.setState({
        otherSettingErrorMessage: "",
        otherSettingSuccessMessage: "",
        disabled: true,
        client_setting_button: true,
      });

      const saveResponce = await editClientNotes(
        auth.getAccount(),
        auth.getToken(),
        clientNotes
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          otherSettingErrorMessage:
            "There is some error while updating the Content.",
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          otherSettingErrorMessage: "",
          otherSettingSuccessMessage: "Content updated successfully.",
          disabled: false,
          client_setting_button: false,
        });
        setTimeout(() => {
          this.setState({
            otherSettingErrorMessage: "",
            otherSettingSuccessMessage: "",
            disabled: false,
            client_setting_button: false,
          });
          this.get_other_settings();
        }, 3000);
      } else {
        this.setState({
          otherSettingErrorMessage: saveResponce.data.message,
          otherSettingSuccessMessage: "",
          disabled: false,
          client_setting_button: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleEditorClientNotes = (updatedContent) => {
    //console.log("updatedContent: ", updatedContent);
    this.setState({ clientNotes: updatedContent });
  };

  getInitialSelectedOption = (value) => {
    // Retrieve the initial selected option from your options array
    let options = this.getOption(); // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  getInitialSelectedTermOption = (value) => {
    // Retrieve the initial selected option from your options array
    let options = this.getTermOptions(); // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  getOption = () => {
    const currencyOptions = [
      { value: "usd", label: "USD - US Dollar" },
      { value: "eur", label: "EUR - Euro" },
      { value: "gbp", label: "GBP - British Pound" },
      { value: "jpy", label: "JPY - Japanese Yen" },
      { value: "cny", label: "CNY - Chinese Yuan" },
      { value: "inr", label: "INR - Indian Rupee" },
      { value: "aud", label: "AUD - Australian Dollar" },
      { value: "cad", label: "CAD - Canadian Dollar" },
      { value: "chf", label: "CHF - Swiss Franc" },
      { value: "sek", label: "SEK - Swedish Krona" },
      { value: "nzd", label: "NZD - New Zealand Dollar" },
      { value: "sgd", label: "SGD - Singapore Dollar" },
      { value: "hkd", label: "HKD - Hong Kong Dollar" },
      { value: "krw", label: "KRW - South Korean Won" },
      { value: "brl", label: "BRL - Brazilian Real" },
      { value: "zar", label: "ZAR - South African Rand" },
      { value: "rub", label: "RUB - Russian Ruble" },
      { value: "try", label: "TRY - Turkish Lira" },
      { value: "mxn", label: "MXN - Mexican Peso" },
      { value: "idr", label: "IDR - Indonesian Rupiah" },
    ];
    return currencyOptions;
  };

  getTermOptions = () => {
    const currencyOptions = [
      { value: "Net 30", label: "Net 30" },
      { value: "Net 60", label: "Net 60" },
      { value: "Net 90", label: "Net 90" },
      { value: "Due on Receipt", label: "Due on Receipt" },
      { value: "2/10 Net 30", label: "2/10 Net 30" },
      { value: "1/10 Net 30", label: "1/10 Net 30" },
      { value: "2/10 Net 60", label: "2/10 Net 60" },
      { value: "3/10 Net 30", label: "3/10 Net 30" },
      { value: "Net 45", label: "Net 45" },
      { value: "Net 120", label: "Net 120" },
      { value: "Cash on Delivery (COD)", label: "Cash on Delivery (COD)" },
      { value: "Cash in Advance (CIA)", label: "Cash in Advance (CIA)" },
      { value: "Stage Payments", label: "Stage Payments" },
      { value: "End of Month (EOM)", label: "End of Month (EOM)" },
    ];
    return currencyOptions;
  };

  get_quotation_settings = async () => {
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      tableLoaderQuote: true,
    });
    const servicesResponce = await getQuotationSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_quotation_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
        tableLoaderQuote: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        quotation_notes:
          servicesResponce.data.quotationSettings[0]["quotation_notes"],
        payment_terms:
          servicesResponce.data.quotationSettings[0]["payment_terms"],
        selected_payment_terms: this.getInitialSelectedTermOption(
          servicesResponce.data.quotationSettings[0]["payment_terms"]
        ),
        currency: servicesResponce.data.quotationSettings[0]["currency"],
        selected_currency: this.getInitialSelectedOption(
          servicesResponce.data.quotationSettings[0]["currency"]
        ),
        contact_person:
          servicesResponce.data.quotationSettings[0]["contact_person"],
        contact_email:
          servicesResponce.data.quotationSettings[0]["contact_email"],
        validity: servicesResponce.data.quotationSettings[0]["validity"],
        decimalPoint:
          servicesResponce.data.quotationSettings[0]["decimal_point"],
        isEditorReady: true,
        tableLoaderQuote: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        quotation_notes: "",
        payment_terms: "",
        currency: "",
        contact_person: "",
        contact_email: "",
        validity: "",
        tableLoaderQuote: false,
      });
    } else {
      this.setState({
        quotationErrorMessage:
          "There is some error while getting quotation settings.",
        quotationSuccessMessage: "",
        tableLoaderQuote: false,
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_quotation_settings = async () => {
    let {
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      decimalPoint,
    } = this.state;
    this.setState({
      quotationErrorMessage: "",
      quotationSuccessMessage: "",
      quotation_button: true,
    });
    var is_validated = true;

    /*if (quotation_notes === "") {
      this.setState({
        quotationErrorMessage: "Quotation notes are required.",
        quotation_button: false,
      });
      is_validated = false;
    } else if (payment_terms === "") {
      this.setState({
        quotationErrorMessage: "Payment terms are required.",
        quotation_button: false,
      });
      is_validated = false;
    }*/
    if (decimalPoint < 2 || decimalPoint > 6) {
      this.setState({
        quotationErrorMessage:
          "Please enter a number between 2 and 6 for decimal points.",
        quotation_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addQuotationSettings(
        auth.getAccount(),
        auth.getToken(),
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        decimalPoint
      );
      console.log("add_quotation_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
          quotationSuccessMessage: "",
          quotation_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        Cookies.remove("decimalPoint__");
        Cookies.set(
          "decimalPoint__",
          CryptoJS.AES.encrypt(
            decimalPoint.toString(),
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
        this.setState({
          quotationErrorMessage: "",
          quotationSuccessMessage: "Quotation settings added successfully.",
          quotation_button: false,
        });
      } else {
        this.setState({
          quotationErrorMessage:
            "There is some error while adding quotation settings.",
          quotationSuccessMessage: "",
          quotation_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ quotationErrorMessage: "", quotationSuccessMessage: "" });
    }, 5000);
  };

  get_commission_settings = async () => {
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
    });
    const servicesResponce = await getCommissionSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_commission_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        commissionErrorTitle: "System Error",
        commissionErrorMessage:
          "There is some error while getting commission settings.",
        commissionSuccessMessage: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        commission_type:
          servicesResponce.data.commissionSettings[0]["commission_type"],
        commission_value_type:
          servicesResponce.data.commissionSettings[0]["commission_value_type"],
        commission_value:
          servicesResponce.data.commissionSettings[0]["commission_value"],
        apply_commission:
          servicesResponce.data.commissionSettings[0]["apply_commission"],
        draw_threshold:
          servicesResponce.data.commissionSettings[0]["draw_threshold"].toFixed(this.decimalPoint),
        isEditorReady: true,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        commission_value: "",
      });
    } else {
      this.setState({
        commissionErrorTitle: "System Error",
        commissionErrorMessage:
          "There is some error while getting commission settings.",
        commissionSuccessMessage: "",
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_commission_settings = async () => {
    let {
      commission_type,
      commission_value_type,
      commission_value,
      apply_commission,
      draw_threshold
    } = this.state;
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commission_button: true,
    });
    var is_validated = true;

    if (commission_value === "") {
      this.setState({
        commissionErrorTitle: "Missing Field",
        commissionErrorMessage: "Commission value is required.",
        commission_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addCommissionSettings(
        auth.getAccount(),
        auth.getToken(),
        commission_type,
        commission_value_type,
        commission_value,
        apply_commission,
        draw_threshold
      );
      console.log("add_quotation_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          commissionErrorTitle: "",
          commissionErrorMessage: "",
          commissionSuccessMessage: "Commission settings added successfully.",
          commission_button: false,
        });
      } else {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        commissionErrorMessage: "",
        commissionErrorTitle: "",
        commissionSuccessMessage: "",
      });
    }, 5000);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        currency: changed_value.value,
        selected_currency: changed_value,
      }));
    } else {
      this.setState({ selected_currency: null });
    }
  };

  handleChangeSearchTerms = async (changed_value) => {
    console.log("handleChangeSearchTerms: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        payment_terms: changed_value.value,
        selected_payment_terms: changed_value,
      }));
    } else {
      this.setState({ selected_payment_terms: null });
    }
  };

  handleEditorBank = (updatedContent) => {
    //console.log("updatedContent: ", updatedContent);
    this.setState({ bank_account_details: updatedContent });
  };

  handleEditorQuotationNotes = (updatedContent) => {
    //console.log("updatedContent: ", updatedContent);
    this.setState({ quotation_notes: updatedContent });
  };

  get_signatory_settings = async () => {
    this.setState({
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingNotFound: "",
      signatory_button: false,
      tableLoader: true,
    });
    const notesResponce = await getSignatorySettings(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        signatoryDetails: notesResponce.data.data,
        isEditorReady: true,
        tableLoader: false,
        columnsignatoryDetails: [
          {
            name: "name",
            label: "Name",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "title",
            label: "Title",
          },
          {
            name: "company",
            label: "Company",
          },
          {
            name: "signature_image",
            label: "Signature",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.showSignature(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "dateAdded",
            label: "Date Added",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },

          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip
                                    title="Delete Signatory"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.deleteSignatory(value)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em class="icon ni ni-trash"></em>
                                      <span>Delete</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Update Signatory"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.updateSignatory(value)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em class="icon ni ni-edit"></em>
                                      <span>Update</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      //window.location.replace("/error");
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  showSignature = (value, tableMeta) => {
    let signature = tableMeta.rowData[3];
    if (signature !== "") {
      signature = APP_LIVE_URL + "files_data/nda_signatures/" + signature;
      return (
        <div>
          <img style={{ width: "200px" }} src={signature}></img>
        </div>
      );
    } else {
      return "---";
    }
  };

  addSignatoryForm = async () => {
    this.setState({
      signatorySettingSuccessMessage: "",
      signatorySettingErrorMessage: "",
      signatorySettingErrorTitle: "",
      signatorySettingNotFound: "",
      signatory_button: false,
    });
    window.$("#modalAddSignatory").modal("show");
  };

  fieldValidationUsername = () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      signatory_email,
      signatory_phone,
      signatory_address,
      signatory_address2,
      signatory_city,
      signatory_zip,
      signatory_state,
      signatory_country,
      add_picture_name,
      isFileUploaded,
    } = this.state;

    if (signatory_company === "") {
      this.setState({
        signatorySettingErrorMessage: "Company name is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_name === "") {
      this.setState({
        signatorySettingErrorMessage: "Legal name is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_title === "") {
      this.setState({
        signatorySettingErrorMessage: "Title is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_email === "") {
      this.setState({
        signatorySettingErrorMessage: "Email is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (ValidateEmail(signatory_email) === false) {
      this.setState({
        signatorySettingErrorMessage: "Invalid email format.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_phone === "") {
      this.setState({
        signatorySettingErrorMessage: "Phone is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (!this.isPhoneValid(signatory_phone)) {
      this.setState({
        signatorySettingErrorMessage: "Invalid phone format.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_address === "") {
      this.setState({
        signatorySettingErrorMessage: "Address is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_city === "") {
      this.setState({
        signatorySettingErrorMessage: "City is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_state === "") {
      this.setState({
        signatorySettingErrorMessage: "State is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_zip === "") {
      this.setState({
        signatorySettingErrorMessage: "Zip is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_country === "") {
      this.setState({
        signatorySettingErrorMessage: "Country is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (add_picture_name === false) {
      this.setState({
        signatorySettingErrorMessage: "Signature is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      this.setState({
        signatorySettingErrorMessage: "",
        signatorySettingErrorTitle: "",
        disabled: true,
      });
    }

    if (!isFileUploaded) {
      this.setState({
        signatorySettingErrorMessage: "Signature is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    }

    return true;
  };

  handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    console.log("Selected file extension:", fileExtension);

    if (!allowedFileTypes.includes("." + fileExtension)) {
      console.log("Invalid file type:", fileExtension);
      this.setState({
        signatorySettingErrorMessage:
          "Invalid file type. Please select a valid file format (e.g., .png, .jpg, .jpeg).",
        signatorySettingErrorTitle: "Invalid Type",
        add_picture: "",
        add_picture_name: "",
        isFileUploaded: false,
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    console.log("maxSizeBytes", maxSizeBytes);
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        signatorySettingErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        signatorySettingErrorTitle: "Error",
        isFileUploaded: false,
      });
      return;
    }

    // Clear any previous errors if file passes validations
    this.setState({
      add_picture: URL.createObjectURL(selectedFile),
      signatorySettingErrorTitle: "",
      add_picture_name: selectedFile.name,
      isFileUploaded: true,
    });
  };

  /*handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        signatorySettingErrorMessage:
          "Invalid file type. Please select a valid file format.",
        signatorySettingErrorTitle: "Invalid Type",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        signatorySettingErrorMessage:
          "File size exceeds the limit (5MB). Please choose a smaller file.",
        signatorySettingErrorTitle: "Size limit exceeded",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Image dimension validation
    const imageReader = new FileReader();

    imageReader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      // Define dimensions based on image type
      let maxWidth, maxHeight, minWidth, minHeight;

      maxWidth = 300;
      maxHeight = 66;
      minWidth = 200;
      minHeight = 44;

      // Wait for the image to be fully loaded before checking dimensions
      image.onload = () => {
        if (image.width > maxWidth || image.height > maxHeight) {
          this.setState({
            signatorySettingErrorMessage: `Image dimensions exceed the limit (${maxWidth}x${maxHeight}). Please choose a smaller image.`,
            signatorySettingErrorTitle: "Invalid Height/Width",
            add_picture: "",
            add_picture_name: "",
          });
        } else if (image.width < minWidth || image.height < minHeight) {
          this.setState({
            signatorySettingErrorMessage: `Image dimensions should be at least (${minWidth}x${minHeight}). Please choose a larger image.`,
            signatorySettingErrorTitle: "Invalid Height/Width",
            add_picture: "",
            add_picture_name: "",
          });
        } else {
          // Clear any previous errors
          this.setState({
            add_picture: selectedFile,
            signatorySettingErrorMessage: "",
            add_picture_name: selectedFile.name,
          });
        }
      };
    };

    imageReader.readAsDataURL(selectedFile);
  };*/

  submitSignatory = async () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      signatory_email,
      signatory_phone,
      signatory_address,
      signatory_address2,
      signatory_city,
      signatory_zip,
      signatory_state,
      signatory_country,
      signatory_status,
      add_picture,
      add_picture_name,
    } = this.state;
    this.setState({
      signatory_button: true,
    });
    const croppedBlob = await this.getCroppedBlob();
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", croppedBlob, add_picture_name);
      formData.append("fileName", add_picture_name);
      formData.append("signatory_name", signatory_name);
      formData.append("signatory_company", signatory_company);
      formData.append("signatory_title", signatory_title);
      formData.append("signatory_email", signatory_email);
      formData.append("signatory_phone", signatory_phone);
      formData.append("signatory_address", signatory_address);
      formData.append("signatory_address2", signatory_address2);
      formData.append("signatory_city", signatory_city);
      formData.append("signatory_state", signatory_state);
      formData.append("signatory_zip", signatory_zip);
      formData.append("signatory_country", signatory_country);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "resellers/submit_signatory",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                signatorySettingErrorMessage: "Record not found.",
                signatorySettingErrorTitle: "System Error",
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage: response.data.message,
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (
              response.data.status === 409 &&
              response.data.message === "Address validation failed"
            ) {
              const formattedErrorMessage = response.data.errors
                ? String(response.data.errors).replace(/,/g, "<br />")
                : "Address validation failed";

              this.setState({
                signatorySettingErrorTitle: "Error",
                signatorySettingErrorMessage: formattedErrorMessage,
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                signatorySettingSuccessMessage:
                  "Comapny profile added successfully.",
                signatorySettingErrorMessage: "",
                signatorySettingErrorTitle: "",
                showLoginButton: true,
                signatory_title: "",
                add_picture: "",
                signatory_company: "",
                signatory_name: "",
                signatory_email: "",
                signatory_phone: "",
                signatory_address: "",
                signatory_address2: "",
                signatory_city: "",
                signatory_zip: "",
                signatory_state: "",
                signatory_country: "",
                brightness: "",
              });

              setTimeout(() => {
                this.get_signatory_settings();
                window.$("#modalAddSignatory").modal("hide");
              }, 3000);
            } else {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage:
                  "There is some error while adding the comapny profile.",
                signatorySettingSuccessMessage: "",
                signatory_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          signatorySettingErrorTitle: "System Error",
          signatorySettingErrorMessage:
            "There is some error while adding the comapny profile.",
          disabled: false,
          successMessage: "",
          signatory_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
    }
    const modalBody = document.getElementById("modalAddSignatory");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
  };

  deleteSignatory = async (signatory_id) => {
    this.setState({
      signatory_id: signatory_id,
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: false,
    });
    window.$("#modalDeleteSignatory").modal("show");
  };

  closeDeleteSignatory = () => {
    window.$("#modalDeleteSignatory").modal("hide");
  };

  submitDeleteSignatory = async () => {
    const { signatory_id } = this.state;
    this.setState({
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: true,
    });
    const notesResponce = await deleteSignatorySettings(
      auth.getAccount(),
      auth.getToken(),
      signatory_id
    );

    //console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      notesResponce.data.status === 404 &&
      notesResponce.data.message === "not_found"
    ) {
      this.setState({
        signatoryDeleteSuccessMessage: "",
        signatoryDeleteErrorMessage: "Company profile not found.",
        signatoryDeleteErrorTitle: "Not Found",

        signatory_button_delete: false,
      });
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      this.setState({
        signatoryDeleteSuccessMessage: "Company profile deleted successfully.",
        signatoryDeleteErrorMessage: "",
        signatoryDeleteErrorTitle: "",
        signatory_button_delete: false,
      });
      setTimeout(() => {
        this.get_signatory_settings();
        window.$("#modalDeleteSignatory").modal("hide");
      }, 3000);
    } else {
      this.setState({
        signatoryDeleteSuccessMessage: "",
        signatoryDeleteErrorMessage:
          "There is some error while deleting the profile.",
        signatoryDeleteErrorTitle: "Error",

        signatory_button_delete: false,
      });
    }
  };

  handleEditorWire = (content, editor) => {
    this.setState({
      wire_transfer_details: content,
    });
    //console.log("Content was updated:", content);
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  onCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;
    const { width, height } = cropper.getCropBoxData();

    // Define dimensions based on the specified constraints
    const minWidth = 200;
    const minHeight = 44;
    const maxWidth = 300;
    const maxHeight = 66;

    // Calculate new dimensions to fit within constraints
    let newWidth = Math.min(Math.max(width, minWidth), maxWidth);
    let newHeight = Math.min(Math.max(height, minHeight), maxHeight);

    // Apply the new dimensions
    cropper.setCropBoxData({ width: newWidth, height: newHeight });

    // Update dimensions directly in the DOM
    const cropSizeElement = document.getElementById("cropSize");
    if (cropSizeElement) {
      cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
        newWidth
      )} x H: ${Math.round(newHeight)}`;
    }
  };

  rotateImage = (degree) => {
    const cropper = this.cropperRef.current.cropper;
    cropper.rotate(degree);
  };

  handleZoomIn = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(0.1); // Adjust zoom factor as needed
  };

  handleZoomOut = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.zoom(-0.1); // Adjust zoom factor as needed
  };

  flipBoth = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1);
  };

  flipHorizontal = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(-1, 1);
  };

  flipVertical = () => {
    const cropper = this.cropperRef.current.cropper;
    cropper.scale(1, -1);
  };

  getCroppedBlob = () => {
    return new Promise((resolve, reject) => {
      const imageElement = this.cropperRef.current;

      if (imageElement && imageElement.cropper) {
        const cropper = imageElement.cropper;
        const croppedCanvas = cropper.getCroppedCanvas();

        if (croppedCanvas) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = croppedCanvas.width;
          canvas.height = croppedCanvas.height;

          ctx.filter = `brightness(${this.state.brightness}%)`;
          ctx.drawImage(croppedCanvas, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/png");
        } else {
          reject(new Error("Failed to get cropped canvas."));
        }
      } else if (this.state.add_picture_existing) {
        fetch(this.state.add_picture_existing)
          .then((response) => response.blob())
          .then((blob) => resolve(blob))
          .catch((error) =>
            reject(new Error("Failed to fetch existing image."))
          );
      } else {
        // Prevent rejection by resolving with null if no cropper and no existing image
        resolve(null);
      }
    });
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        console.log("addressComponents", addressComponents);
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });

        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }

        this.setState({
          signatory_city: city && city !== "" ? city : "",
          signatory_state: state && state !== "" ? state : "",
          signatory_zip: zipCode && zipCode !== "" ? zipCode : "",
          signatory_country: country && country !== "" ? country : "",
          signatory_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          signatory_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleReady = () => {
    const cropper = this.cropperRef.current.cropper;
    const containerData = cropper.getContainerData();
    const cropBoxData = cropper.getCropBoxData();
    const imageData = cropper.getImageData();

    const newWidth = imageData.naturalWidth * 0.5;
    const newHeight = imageData.naturalHeight * 0.5; // Adjust the ratio as needed
    const newLeft = (containerData.width - newWidth) / 2;
    const newTop = (containerData.height - newHeight) / 2;

    cropper.setCropBoxData({
      left: newLeft,
      top: newTop,
      width: newWidth,
      height: newHeight,
    });
  };

  handleResetCrop = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;
    this.handleReady();
    // Reset the crop box to its initial state
    cropper.reset();
    this.setState({ brightness: 100 });
  };

  handleBrightnessChange = (e) => {
    this.setState({ brightness: parseInt(e.target.value) });
  };

  toggleBrightnessRange = () => {
    this.setState((prevState) => ({
      showBrightnessRange: !prevState.showBrightnessRange,
    }));
  };

  fieldUpdateValidationUsername = () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      signatory_email,
      signatory_phone,
      signatory_address,
      signatory_address2,
      signatory_city,
      signatory_zip,
      signatory_state,
      signatory_country,
      add_picture_name_update,
    } = this.state;

    if (signatory_company === "") {
      this.setState({
        signatorySettingErrorMessage: "Company name is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_name === "") {
      this.setState({
        signatorySettingErrorMessage: "Legal name is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_title === "") {
      this.setState({
        signatorySettingErrorMessage: "Title is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_email === "") {
      this.setState({
        signatorySettingErrorMessage: "Email is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (ValidateEmail(signatory_email) === false) {
      this.setState({
        signatorySettingErrorMessage: "Invalid email format.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_phone === "") {
      this.setState({
        signatorySettingErrorMessage: "Phone is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (!this.isPhoneValid(signatory_phone)) {
      this.setState({
        signatorySettingErrorMessage: "Invalid phone format.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_address === "") {
      this.setState({
        signatorySettingErrorMessage: "Address is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_city === "") {
      this.setState({
        signatorySettingErrorMessage: "City is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_state === "") {
      this.setState({
        signatorySettingErrorMessage: "State is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_zip === "") {
      this.setState({
        signatorySettingErrorMessage: "Zip is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (signatory_country === "") {
      this.setState({
        signatorySettingErrorMessage: "Country is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else if (add_picture_name_update === "") {
      this.setState({
        signatorySettingErrorMessage: "Signatory signature is required.",
        signatorySettingErrorTitle: "Missing Fields",
        signatory_button: false,
      });
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      this.setState({
        signatorySettingErrorMessage: "",
        signatorySettingErrorTitle: "",
        disabled: true,
      });
    }

    return true;
  };

  handleFileChangeUpdate = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [".png", ".jpg", ".jpeg"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    console.log("Selected file extension:", fileExtension);

    if (!allowedFileTypes.includes("." + fileExtension)) {
      console.log("Invalid file type:", fileExtension);
      this.setState({
        signatorySettingErrorMessage:
          "Invalid file type. Please select a valid file format (e.g., .png, .jpg, .jpeg).",
        signatorySettingErrorTitle: "Invalid Type",
        add_picture_update: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 5MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    console.log("maxSizeBytes", maxSizeBytes);
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        signatorySettingErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        signatorySettingErrorTitle: "Error",
      });
      return;
    }

    // Clear any previous errors if file passes validations
    this.setState({
      add_picture_update: URL.createObjectURL(selectedFile),
      signatorySettingErrorTitle: "",
      add_picture_name_update: selectedFile.name,
    });
  };

  updateSignatory = async (signatory_id) => {
    this.setState({
      signatory_id: signatory_id,
      signatoryDeleteSuccessMessage: "",
      signatoryDeleteErrorMessage: "",
      signatoryDeleteErrorTitle: "",
      signatoryDeleteNotFound: "",
      signatory_button_delete: false,
      signatory_name: "",
      signatory_company: "",
      signatory_title: "",
      signatory_email: "",
      signatory_phone: "",
      signatory_address: "",
      signatory_address2: "",
      signatory_city: "",
      signatory_state: "",
      signatory_zip: "",
    });

    window.$("#modalUpdateSignatory").modal("show");

    const notesResponce = await getSignatorySettingsByID(
      auth.getAccount(),
      auth.getToken(),
      signatory_id
    );

    //console.log("get_signatory_settings_by_id: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      const signatoryData = notesResponce.data.data[0];
      this.setState({
        signatory_name: signatoryData.name,
        signatory_company: signatoryData.company,
        signatory_title: signatoryData.title,
        signatory_email: signatoryData.email || "",
        signatory_phone: signatoryData.phone || "",
        signatory_address: signatoryData.address || "",
        signatory_address2: signatoryData.addsignatory_address2 || "",
        signatory_city: signatoryData.city || "",
        signatory_state: signatoryData.state || "",
        signatory_zip: signatoryData.zip || "",
        signatory_country: signatoryData.country || "",
        // add_picture_update:
        //   APP_LIVE_URL +
        //   "files_data/nda_signatures/" +
        //   signatoryData.signature_image,
        add_picture_existing:
          APP_LIVE_URL +
          "files_data/nda_signatures/" +
          signatoryData.signature_image,
        add_picture_name_update: signatoryData.fileName,
        isEditorReady: true,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  submitUpdateSignatory = async () => {
    let {
      signatory_name,
      signatory_company,
      signatory_title,
      signatory_email,
      signatory_phone,
      signatory_address,
      signatory_address2,
      signatory_city,
      signatory_zip,
      signatory_state,
      signatory_country,
      signatory_id,
      add_picture_name_update,
      add_picture_existing,
      add_picture_update,
    } = this.state;
    this.setState({
      signatory_button: true,
    });
    const modalBody = document.getElementById("modalUpdateSignatory");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    const croppedBlob = await this.getCroppedBlob();
    if (this.fieldUpdateValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("signatory_name", signatory_name);
      formData.append("signatory_company", signatory_company);
      formData.append("signatory_title", signatory_title);
      formData.append("signatory_email", signatory_email);
      formData.append("signatory_phone", signatory_phone);
      formData.append("signatory_address", signatory_address);
      formData.append("signatory_address2", signatory_address2);
      formData.append("signatory_city", signatory_city);
      formData.append("signatory_state", signatory_state);
      formData.append("signatory_zip", signatory_zip);
      formData.append("signatory_country", signatory_country);
      formData.append("signatory_id", signatory_id);
      if (croppedBlob && add_picture_update) {
        // Append croppedBlob if available
        formData.append("file", croppedBlob, add_picture_name_update);
      } else {
        // Convert existing image URL to Blob and append
        try {
          const response = await fetch(add_picture_existing);
          const blob = await response.blob();
          formData.append("file", blob, add_picture_name_update);
        } catch (error) {
          console.error("Failed to fetch existing image:", error);
          this.setState({
            signatorySettingErrorTitle: "System Error",
            signatorySettingErrorMessage: "Failed to fetch existing image.",
            signatory_button: false,
          });
          return; // Exit function if fetch fails
        }
      }
      formData.append("fileName", add_picture_name_update);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "resellers/submit_signatory_update",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                signatorySettingErrorMessage: "Record not found.",
                signatorySettingErrorTitle: "System Error",
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage: response.data.message,
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (
              response.data.status === 409 &&
              response.data.message === "Address validation failed"
            ) {
              const formattedErrorMessage = response.data.errors
                ? String(response.data.errors).replace(/,/g, "<br />")
                : "Address validation failed";

              this.setState({
                signatorySettingErrorTitle: "Error",
                signatorySettingErrorMessage: formattedErrorMessage,
                signatory_button: false,
                signatorySettingSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                signatorySettingSuccessMessage:
                  "Comapny profile updated successfully.",
                signatorySettingErrorMessage: "",
                signatorySettingErrorTitle: "",
                showLoginButton: true,
              });

              setTimeout(() => {
                this.get_signatory_settings();
                window.$("#modalUpdateSignatory").modal("hide");
              }, 3000);
            } else {
              this.setState({
                signatorySettingErrorTitle: "System Error",
                signatorySettingErrorMessage:
                  "There is some error while updating the company profile.",
                signatorySettingSuccessMessage: "",
                signatory_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          signatorySettingErrorTitle: "System Error",
          signatorySettingErrorMessage:
            "There is some error while adding the company profile.",
          disabled: false,
          successMessage: "",
          signatory_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          signatorySettingErrorTitle: "",
          signatorySettingErrorMessage: "",
        });
      }, 4000);
    }
  };

  onCropUpdate = () => {
    const imageElement = this.cropperRef.current;
    const cropper = imageElement.cropper;
    const { width, height } = cropper.getCropBoxData();

    // Define dimensions based on the specified constraints
    const minWidth = 200;
    const minHeight = 44;
    const maxWidth = 300;
    const maxHeight = 66;

    // Calculate new dimensions to fit within constraints
    let newWidth = Math.min(Math.max(width, minWidth), maxWidth);
    let newHeight = Math.min(Math.max(height, minHeight), maxHeight);

    // Apply the new dimensions
    cropper.setCropBoxData({ width: newWidth, height: newHeight });

    // Update dimensions directly in the DOM
    const cropSizeElement = document.getElementById("cropSize");
    if (cropSizeElement) {
      cropSizeElement.textContent = `Current Crop Size: W: ${Math.round(
        newWidth
      )} x H: ${Math.round(newHeight)}`;
    }
  };

  render() {
    const { showBrightnessRange, brightness } = this.state;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-setting"></em> Settings
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                <div className="col-xxl-12">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "general_settings" ||
                              this.state.settings_type === ""
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>General Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "smtp_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={() => this.get_smtp_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>SMTP Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "payment_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItem7"
                            onClick={() => this.get_payment_settings()}
                          >
                            <em className="icon ni ni-cc-alt"></em>
                            <span>Payment Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "quotation_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItemQuotation"
                            onClick={() => this.get_quotation_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>Quotation Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "commission_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#tabItemCommission"
                            onClick={() => this.get_commission_settings()}
                          >
                            <em className="icon ni ni-emails"></em>
                            <span>Commission Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "other_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#other_settings"
                            onClick={() => this.get_other_settings()}
                          >
                            <em className="icon ni ni-setting"></em>
                            <span>Other Settings</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.settings_type === "signatory_settings"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            href="#signatory_settings"
                            onClick={() => this.get_signatory_settings()}
                          >
                            <em className="icon ni ni-contact"></em>
                            <span>Company Profile Settings</span>
                          </a>
                        </li>
                        <li>
                          <div className="dropdown mt-3">
                            <a
                              className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                              data-toggle="dropdown"
                              data-offset="0,5"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-s">
                              <ul className="link-list-plain">
                                <li>
                                  <a
                                    data-placement="left"
                                    data-toggle="tab"
                                    href="#rfq_settings"
                                    onClick={() => this.get_rfq_settings()}
                                    title="RFQ Settings"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em
                                      className="icon ni ni-file-docs"
                                      style={{ marginRight: "7px" }}
                                    ></em>
                                    <span>RFQ Settings</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={
                            this.state.settings_type === "general_settings" ||
                            this.state.settings_type === ""
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem5"
                        >
                          <div className="row g-gs">
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-user"></em>{" "}
                                      Channel Partner Signup Link
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        {this.state.errorMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-danger">
                                              <div className="alert-text">
                                                <h4>Error</h4>
                                                <p>{this.state.errorMessage}</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {this.state.successMessage !== "" ? (
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-pro alert-success">
                                              <div className="alert-text">
                                                <h4>Success</h4>
                                                <p>
                                                  {this.state.successMessage}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="example-alert">
                                          <div className="alert alert-light alert-icon">
                                            <em className="icon ni ni-alert-circle"></em>{" "}
                                            <strong>NOTE: </strong> Use this
                                            link to invite Channel Partners to
                                            join.{" "}
                                          </div>
                                        </div>

                                        <div
                                          className="caption-text"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div
                                            className="example-alert"
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <div className="alert alert-light">
                                              <em className="icon ni ni-link"></em>{" "}
                                              {this.state.signup_link &&
                                              this.state.signup_link !== "" ? (
                                                <>{this.state.signup_link}</>
                                              ) : (
                                                <>
                                                  Channel partner signup link is
                                                  not available. Please create a
                                                  new one by clicking on
                                                  "Regenerate" button.
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.reg_button === false ? (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              onClick={() =>
                                                this.regenerate_link()
                                              }
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-swap"></em>{" "}
                                              Regenerate
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}

                                          {this.state.cancel_button ===
                                          false ? (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              onClick={() => this.cancel_link()}
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-danger"
                                              style={{ marginRight: "15px" }}
                                              disabled
                                            >
                                              <em className="icon ni ni-cross-round"></em>{" "}
                                              Cancel
                                              <div
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            </button>
                                          )}
                                          {this.state.signup_link &&
                                          this.state.signup_link !== "" ? (
                                            <CopyToClipboard
                                              text={this.state.signup_link}
                                              onCopy={() =>
                                                this.setState({ copied: true })
                                              }
                                            >
                                              <button className="btn btn-success">
                                                <em className="icon ni ni-copy"></em>{" "}
                                                Copy to clipboard{" "}
                                                {this.state.copied ? (
                                                  <span
                                                    style={{ color: "white" }}
                                                  >
                                                    <em className="icon ni ni-done"></em>
                                                  </span>
                                                ) : null}
                                              </button>
                                            </CopyToClipboard>
                                          ) : null}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-signin"></em>{" "}
                                      Channel Partner Login URL
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    <div className="example-alert">
                                      <div
                                        className="alert alert-light alert-icon"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        <em className="icon ni ni-link"></em>{" "}
                                        {this.state.login_url &&
                                        this.state.login_url !== "" ? (
                                          <>{this.state.login_url}</>
                                        ) : (
                                          <>
                                            Channel partner login link is not
                                            available. Please create a new one
                                            by clicking on "Regenerate" button.
                                          </>
                                        )}
                                      </div>
                                      {/*{this.state.reg_button_login === false ? (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                onClick={() => this.regenerate_link()}
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                style={{ marginRight: "15px" }}
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Regenerate
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            )}*/}
                                      {this.state.login_url &&
                                      this.state.login_url !== "" ? (
                                        <CopyToClipboard
                                          text={this.state.login_url}
                                          onCopy={() =>
                                            this.setState({ copied2: true })
                                          }
                                        >
                                          <button className="btn btn-success">
                                            <em className="icon ni ni-copy"></em>{" "}
                                            Copy to clipboard{" "}
                                            {this.state.copied2 ? (
                                              <span style={{ color: "white" }}>
                                                <em className="icon ni ni-done"></em>
                                              </span>
                                            ) : null}
                                          </button>
                                        </CopyToClipboard>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xxl-6">
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="card-inner">
                                    <h5 className="card-title">
                                      <em className="icon ni ni-camera"></em>{" "}
                                      Upload Logo and Signature
                                    </h5>
                                    <h6 className="card-subtitle mb-2">
                                      &nbsp;
                                    </h6>

                                    {this.state.tableLoader === true ? (
                                      tableLoader()
                                    ) : (
                                      <>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Logo
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="logo"
                                                logo_image={this.state.logo}
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-block">
                                          <div className="card card-bordered">
                                            <div className="card-inner">
                                              <h5 className="card-title">
                                                <em className="icon ni ni-camera"></em>{" "}
                                                Upload Signature
                                              </h5>
                                              <h6 className="card-subtitle mb-2">
                                                &nbsp;
                                              </h6>
                                              <Fileupload
                                                image_type="signature"
                                                logo_image={
                                                  this.state.signature_image
                                                }
                                                reseller_accountno={auth.getAccount()}
                                              ></Fileupload>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "smtp_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem6"
                        >
                          <div className="row g-gs">
                            {this.state.SmtpErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.SmtpErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.SmtpSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.SmtpSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.is_working === "no" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-warning">
                                    <div className="alert-text">
                                      <h4>Warning</h4>
                                      <p>
                                        Your SMTP details are not verified.
                                        Please click "Test SMTP Settings" to
                                        verify the details.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Host or ip address of your smtp server (example: smtp.company.com)"
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    SMTP Host{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_host"
                                    name="smtp_host"
                                    type="text"
                                    label="SMTP Host"
                                    value={this.state.smtp_host}
                                    onChange={this.handleChange}
                                    error={this.state.errorHostName}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Very important: What kind of encryption to use on the SMTP connection."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Security Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_method"
                                    name="smtp_method"
                                    select
                                    label="Security Type"
                                    value={this.state.smtp_method}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setstls1" value="tls">
                                      TLS
                                    </option>
                                    <option key="setssl1" value="ssl">
                                      SSL
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Username for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Username{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_user"
                                    name="smtp_user"
                                    type="text"
                                    label="SMTP User"
                                    value={this.state.smtp_user}
                                    onChange={this.handleChange}
                                    error={this.state.errorUser}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Password for authentication."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    required
                                    id="smtp_password"
                                    name="smtp_password"
                                    type="text"
                                    label="SMTP Password"
                                    value={this.state.smtp_password}
                                    error={this.state.errorPassword}
                                    onChange={this.handleChange}
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The default port is 25, but some SMTP servers use a custom port (example: 587,465)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Port <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="smtp_port"
                                    name="smtp_port"
                                    select
                                    label="Port"
                                    value={this.state.smtp_port}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="port25" value="25">
                                      25
                                    </option>
                                    <option key="port465" value="465">
                                      465
                                    </option>
                                    <option key="port587" value="587">
                                      587
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The sender email address (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Email From{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="from_email"
                                    name="from_email"
                                    type="text"
                                    label="Email From"
                                    value={this.state.from_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="The email address to receive the notifications (example: account@example.com)."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Notifications Email{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="notifications_email"
                                    name="notifications_email"
                                    type="text"
                                    label="Notifications Email"
                                    value={this.state.notifications_email}
                                    onChange={this.handleChange}
                                    error={this.state.errorFromEmail}
                                    inputProps={{
                                      maxLength: 100, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.smtp_test_button === false ? (
                                  <button
                                    type="button"
                                    className={
                                      this.state.is_working === "yes"
                                        ? "btn btn-lg btn-success"
                                        : "btn btn-lg btn-primary"
                                    }
                                    onClick={() => this.verify_smtp_settings()}
                                    style={{ marginRight: "15px" }}
                                    disabled={this.state.disabled}
                                  >
                                    {this.state.is_working === "yes" ? (
                                      <>
                                        <em class="icon ni ni-done"></em>
                                        SMTP Settings Verified
                                      </>
                                    ) : (
                                      "Test SMTP Settings"
                                    )}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Test
                                    SMTP Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                                {this.state.smtp_reg_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() => this.add_smtp_settings()}
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.settings_type === "payment_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItem7"
                        >
                          <div className="row g-gs">
                            {this.state.tableLoaderPayment === true ? (
                              tableLoader()
                            ) : (
                              <>
                                {this.state.paymentErrorMessage !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>
                                            {this.state.paymentErrorMessage}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.paymentSuccessMessage !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {this.state.paymentSuccessMessage}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="col-lg-12">
                                  <h6>Payment Details</h6>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <RichTextEditorComponent
                                      agreementContent={
                                        this.state.bank_account_details
                                      }
                                      onContentChange={this.handleEditorBank}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.payment_reg_button === false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() =>
                                          this.add_payment_settings()
                                        }
                                        disabled={this.state.disabled}
                                      >
                                        Save Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/**** Quotation Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "quotation_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemQuotation"
                        >
                          <div className="row g-gs">
                            {this.state.tableLoaderQuote === true ? (
                              tableLoader()
                            ) : (
                              <>
                                {this.state.quotationErrorMessage !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>Error</h4>
                                          <p>
                                            {this.state.quotationErrorMessage}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.quotationSuccessMessage !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {this.state.quotationSuccessMessage}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="Preferred terms of payment (Net 30, Net 60, 50% Deposit, COD (Cash on Delivery))."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Terms of payment{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <Select
                                        options={this.getTermOptions()}
                                        name="payment_terms"
                                        placeholder="Please select the preferred currency"
                                        value={
                                          this.state.selected_payment_terms
                                        }
                                        autoComplete="off"
                                        emptyMessage="Terms not found"
                                        onChange={this.handleChangeSearchTerms}
                                        isClearable
                                        isSearchable
                                        components={animatedComponents}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            lineHeight: "40px",
                                          }),
                                          menu: (baseStyles) => ({
                                            ...baseStyles,
                                            zIndex: 9999, // Set the same or a higher z-index value
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="The currency in which you would like to receive the payment."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Currency{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <Select
                                        options={this.getOption()}
                                        name="selected_currency"
                                        placeholder="Please select the preferred currency"
                                        value={this.state.selected_currency}
                                        autoComplete="off"
                                        emptyMessage="Currency not found"
                                        onChange={this.handleChangeSearch}
                                        isClearable
                                        isSearchable
                                        components={animatedComponents}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            lineHeight: "40px",
                                          }),
                                          menu: (baseStyles) => ({
                                            ...baseStyles,
                                            zIndex: 9999, // Set the same or a higher z-index value
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        Contact Person{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="contact_person"
                                        name="contact_person"
                                        type="text"
                                        label="Provide the full name"
                                        value={this.state.contact_person}
                                        onChange={this.handleChange}
                                        error={this.state.errorcontact_person}
                                        inputProps={{
                                          maxLength: 50, // Set the maximum length to 10 characters
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        Contact Email{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="contact_email"
                                        name="contact_email"
                                        type="text"
                                        label="Provide the email"
                                        value={this.state.contact_email}
                                        onChange={this.handleChange}
                                        error={this.state.errorcontact_email}
                                        inputProps={{
                                          maxLength: 50, // Set the maximum length to 10 characters
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="Specify the number of days for which this quotation will remain valid."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Validity (Days){" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="validity"
                                        name="validity"
                                        type="text"
                                        label="Quotation Validity"
                                        value={this.state.validity}
                                        onChange={this.handleChange}
                                        error={this.state.errorvalidity}
                                        inputProps={{
                                          maxLength: 4, // Set the maximum length to 10 characters
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="Specify the number of decimal points to be displayed for service rates. Enter a number between 2 and 6."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Decimal Points{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="decimalPoint"
                                        name="decimalPoint"
                                        type="number"
                                        value={this.state.decimalPoint}
                                        onChange={this.handleChange}
                                        error={this.state.errordecimalPoint}
                                        inputProps={{
                                          max: 6,
                                          min: 2,
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <h6>Quotation Notes</h6>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <RichTextEditorComponent
                                      agreementContent={
                                        this.state.quotation_notes
                                      }
                                      onContentChange={
                                        this.handleEditorQuotationNotes
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group"></div>
                                </div>
                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.quotation_button === false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() =>
                                          this.add_quotation_settings()
                                        }
                                        disabled={this.state.disabled}
                                      >
                                        Save Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Quotation Settings ****/}
                        {/**** Commission Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "commission_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemCommission"
                        >
                          <div className="row g-gs">
                            {this.state.commissionErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>{this.state.commissionErrorTitle}</h4>
                                      <p>{this.state.commissionErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.commissionSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.commissionSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Set the default value of how the commission will be applied."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Apply Commission{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="apply_commission"
                                    name="apply_commission"
                                    select
                                    label=""
                                    value={this.state.apply_commission}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst1" value="agent_account">
                                      Channel Partner Account
                                    </option>
                                    <option
                                      key="setst2"
                                      value="product_services"
                                    >
                                      Product & Services
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Billed Commission: Paid when the sale is invoiced or billed to the customer, regardless of whether the payment has been received. Collected Commission: Paid only when the payment from the customer is collected. This can be more common in industries where payment collection might be a prolonged process."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Commission Payment Terms{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_type"
                                    name="commission_type"
                                    select
                                    label=""
                                    value={this.state.commission_type}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst1" value="paid_on_billed">
                                      Paid On Billed
                                    </option>
                                    <option
                                      key="setst2"
                                      value="paid_on_collected"
                                    >
                                      Paid On Collected
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    <Tooltip
                                      title="Agents receive a fixed or percentage based on the profit margin."
                                      placement="right"
                                    >
                                      <em
                                        className="icon ni ni-info"
                                        style={{
                                          color: "#6576ff",
                                        }}
                                      ></em>
                                    </Tooltip>{" "}
                                    Commission Type{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_value_type"
                                    name="commission_value_type"
                                    select
                                    label=""
                                    value={this.state.commission_value_type}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <option key="setst33" value="fixed">
                                      Fixed
                                    </option>
                                    <option key="setst23" value="percentage">
                                      Percentage
                                    </option>
                                  </MutextField>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Commission Value{" "}
                                    {this.state.commission_value_type ===
                                    "percentage"
                                      ? "(%)"
                                      : ""}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="commission_value"
                                    name="commission_value"
                                    type="text"
                                    label=""
                                    value={this.state.commission_value}
                                    onChange={this.handleChange}
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    inputProps={{
                                      maxLength: 9, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Withdraw Threshold{" "}
                                    
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <MutextField
                                    id="draw_threshold"
                                    name="draw_threshold"
                                    type="text"
                                    label=""
                                    value={this.state.draw_threshold}
                                    onChange={this.handleChange}
                                    onKeyPress={() => {
                                      this.checkNumber();
                                    }}
                                    inputProps={{
                                      maxLength: 9, // Set the maximum length to 10 characters
                                    }}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group"></div>
                            </div>
                            <div className="col-md-12 text-right">
                              <div className="form-group">
                                {this.state.commission_button === false ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={() =>
                                      this.add_commission_settings()
                                    }
                                    disabled={this.state.disabled}
                                  >
                                    Save Settings
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-lg btn-primary"
                                    style={{ marginRight: "15px" }}
                                    disabled
                                  >
                                    <em className="icon ni ni-swap"></em> Save
                                    Settings
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**** Commission Settings ****/}
                        {/**** Other Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "other_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="other_settings"
                        >
                          <div className="row g-gs">
                            {this.state.otherSettingErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.otherSettingErrorMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.otherSettingSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.otherSettingSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-lg-12">
                              <h6>Client Notes </h6>
                            </div>
                            {this.state.tableLoaderNotes === true ? (
                              tableLoader()
                            ) : (
                              <>
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <RichTextEditorComponent
                                      agreementContent={this.state.clientNotes}
                                      onContentChange={
                                        this.handleEditorClientNotes
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.client_setting_button ===
                                    false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() => this.saveClientNotes()}
                                        disabled={this.state.disabled}
                                      >
                                        Save Other Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Other Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Other Settings ****/}
                        {/**** Other Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "signatory_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="signatory_settings"
                        >
                          <div className="row g-gs">
                            <div className="col-lg-12">
                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">
                                      <PageTitle
                                        name="Company Profiles"
                                        icon="icon ni ni-user-list"
                                      />
                                    </h3>
                                  </div>
                                  <div
                                    className="nk-block-head-content"
                                    id="HeadContentAgent"
                                  >
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                        data-target="pageMenu"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        className="toggle-expand-content"
                                        data-content="pageMenu"
                                      >
                                        <ul className="nk-block-tools g-3">
                                          <li className="nk-block-tools-opt">
                                            <Link
                                              color="inherit"
                                              onClick={() =>
                                                this.addSignatoryForm()
                                              }
                                              className="btn btn-primary"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                                color: "#fff",
                                              }}
                                            >
                                              <em className="icon ni ni-plus-round"></em>
                                              &nbsp;Add New Profile
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <>
                                {this.state.signatorySettingNotFound !== "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <p>
                                            {
                                              this.state
                                                .signatorySettingNotFound
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.signatoryDetails &&
                                this.state.signatoryDetails.length > 0 ? (
                                  <div className="col-md-12">
                                    <DataTableExtended
                                      columns={
                                        this.state.columnsignatoryDetails
                                      }
                                      tableData={this.state.signatoryDetails}
                                      title=""
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {/**** Signatory Settings ****/}
                        {/**** RFQ Settings ****/}
                        <div
                          className={
                            this.state.settings_type === "rfq_settings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="rfq_settings"
                        >
                          <div className="row g-gs">
                            <div className="col-lg-12">
                              <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                  <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">
                                      <PageTitle
                                        name="RFQ Settings"
                                        icon="icon ni ni-setting"
                                      />
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.rfqSettingErrorMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.rfqSettingErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.rfqSettingSuccessMessage !== "" ? (
                              <div className="col-md-12">
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.rfqSettingSuccessMessage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.tableLoaderRfq === true ? (
                              tableLoader()
                            ) : (
                              <>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        RFQ Title{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="rfqSettingTitle"
                                        name="rfqSettingTitle"
                                        type="text"
                                        label=""
                                        value={this.state.rfqSettingTitle}
                                        onChange={this.handleChange}
                                        inputProps={{
                                          maxLength: 100, // Set the maximum length to 10 characters
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        RFQ Heading{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <MutextField
                                        id="rfqSettingHeading"
                                        name="rfqSettingHeading"
                                        type="text"
                                        label=""
                                        value={this.state.rfqSettingHeading}
                                        onChange={this.handleChange}
                                        inputProps={{
                                          maxLength: 100, // Set the maximum length to 10 characters
                                        }}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        RFQ Short Description{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>
                                    <div className="form-control-group">
                                      <textarea
                                        class="form-control"
                                        id="rfqSettingDesc"
                                        name="rfqSettingDesc"
                                        onChange={this.handleChange}
                                      >
                                        {this.state.rfqSettingDesc}
                                      </textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 text-right">
                                  <div className="form-group">
                                    {this.state.client_rfq_button === false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() => this.saveRfqSettings()}
                                        disabled={this.state.disabled}
                                      >
                                        Save RFQ Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save RFQ Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        {/**** RFQ Settings ****/}
                        {/***** Add Signatory Form *****/}
                        <div
                          class="modal fade flexibleModal"
                          tabindex="-1"
                          id="modalAddSignatory"
                        >
                          <div
                            class="modal-dialog modal-xl modal-dialog-top"
                            role="document"
                          >
                            <div class="modal-content">
                              <a
                                href="#"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                              <div class="modal-header">
                                <h5 class="modal-title">
                                  Add New Company Profile
                                </h5>
                              </div>
                              <div class="modal-body">
                                <div className="row g-gs">
                                  {this.state.signatorySettingErrorMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>
                                              {
                                                this.state
                                                  .signatorySettingErrorTitle
                                              }
                                            </h4>

                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  this.state
                                                    .signatorySettingErrorMessage,
                                              }}
                                            ></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.signatorySettingSuccessMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .signatorySettingSuccessMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Company Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_company"
                                          name="signatory_company"
                                          type="text"
                                          label="Company Name"
                                          value={this.state.signatory_company}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Legal Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_name"
                                          name="signatory_name"
                                          type="text"
                                          label="Legal Name"
                                          value={this.state.signatory_name}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Title <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_title"
                                          name="signatory_title"
                                          type="text"
                                          label="Title"
                                          value={this.state.signatory_title}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Email <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_email"
                                          name="signatory_email"
                                          type="text"
                                          label="Email"
                                          value={this.state.signatory_email}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Phone <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <PhoneInput
                                          required
                                          defaultCountry={"us"}
                                          inputProps={{
                                            name: "signatory_phone",
                                            id: "signatory_phone",
                                            maxLength: 20,
                                          }}
                                          style={{
                                            height: "56px",
                                          }}
                                          value={
                                            this.state.isLoading
                                              ? ""
                                              : this.state.signatory_phone
                                          }
                                          placeholder={
                                            this.state.isLoading
                                              ? "Loading..."
                                              : "Enter the phone number"
                                          }
                                          onChange={(value) =>
                                            this.handleChange({
                                              target: {
                                                name: "signatory_phone",
                                                value,
                                              },
                                            })
                                          }
                                          helperText="Enter the phone number."
                                          label="Phone Number"
                                          ref={this.phoneInputRef}
                                          onFocus={this.handleFocus}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="form-label-group">
                                          <label className="form-label">
                                            Address <sup>*</sup>
                                            <span className="text-danger"></span>
                                          </label>
                                        </div>
                                        <div class="nk-block-head-content">
                                          <ul class="nk-block-tools gx-3">
                                            <li>
                                              <a
                                                onClick={this.handleToggle}
                                                class="text-primary fs-11"
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {this.state.toggleButtonText}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="form-control-group">
                                        {this.state.useGooglePlaces ? (
                                          <GooglePlacesAutocomplete
                                            apiKey={
                                              process.env
                                                .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                            }
                                            selectProps={{
                                              value: this.state
                                                .signatory_address
                                                ? {
                                                    label:
                                                      this.state
                                                        .signatory_address,
                                                    value:
                                                      this.state
                                                        .signatory_address,
                                                  }
                                                : null,
                                              onChange:
                                                this.handleAddressSelect,
                                              placeholder: this.state
                                                .signatory_address
                                                ? null
                                                : "Enter your address..",
                                              styles: {
                                                input: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                  height: "48px",
                                                }),
                                                option: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                }),
                                                singleValue: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                }),
                                                menu: (provided) => ({
                                                  ...provided,
                                                  zIndex: 9,
                                                }),
                                              },
                                            }}
                                            className="googleLocation-autocomplete"
                                          />
                                        ) : (
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="signatory_address"
                                            name="signatory_address"
                                            value={this.state.signatory_address}
                                            placeholder="Enter your address"
                                            onChange={this.handleChange}
                                            style={{
                                              borderColor: this.state
                                                .errorAddress
                                                ? "red"
                                                : "initial",
                                              height: "56px",
                                              border: "1px solid #dbdfea",
                                              fontSize: "0.9375rem",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Apartment, Suite, Etc. <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_address2"
                                          name="signatory_address2"
                                          type="text"
                                          label="Apartment, Suite, Etc."
                                          value={this.state.signatory_address2}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          City <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_city"
                                          name="signatory_city"
                                          type="text"
                                          label="City"
                                          value={this.state.signatory_city}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          State <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_state"
                                          name="signatory_state"
                                          type="text"
                                          label="State"
                                          value={this.state.signatory_state}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Zip <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_zip"
                                          name="signatory_zip"
                                          type="text"
                                          label="Zip"
                                          value={this.state.signatory_zip}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Country <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <div className="form-group">
                                          <Select
                                            className="customStyle-country"
                                            options={this.countryOptions}
                                            value={{
                                              label:
                                                this.state.signatory_country,
                                              value:
                                                this.state.signatory_country,
                                            }}
                                            onChange={this.handleChangeCountry}
                                            placeholder="Select a country"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                minHeight: "56px",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Signature image of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Upload Signature <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group signatory_upload">
                                        <div class="custom-file">
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="add_picture"
                                            name="add_picture"
                                            defaultValue={
                                              this.state.add_picture
                                            }
                                            accept=".png, .jpg, .jpeg"
                                            onChange={this.handleFileChange}
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="add_picture"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    {this.state.add_picture && (
                                      <div style={{ margin: "15px 0 0 0" }}>
                                        <Cropper
                                          src={this.state.add_picture}
                                          style={{
                                            height: 400,
                                            width: "100%",
                                            filter: `brightness(${this.state.brightness}%)`,
                                          }}
                                          aspectRatio={300 / 66}
                                          guides={true}
                                          zoomable={true}
                                          crop={this.onCrop}
                                          ref={this.cropperRef}
                                          rotatable={true}
                                          ready={this.handleReady}
                                        />
                                        <div
                                          style={{ margin: "10px 0 10px 0" }}
                                        >
                                          <p id="cropSize">No crop selected</p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Tooltip
                                            title="Zoom In"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.handleZoomIn()
                                              }
                                            >
                                              <em
                                                className="icon ni ni-zoom-in"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Zoom Out"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.handleZoomOut()
                                              }
                                            >
                                              <em
                                                className="icon ni ni-zoom-out"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Rotate Left"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.rotateImage(-15)
                                              }
                                            >
                                              <em
                                                className="icon ni ni-curve-up-left"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Rotate Right"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.rotateImage(15)
                                              }
                                            >
                                              <em
                                                className="icon ni ni-curve-up-right"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>

                                          <Tooltip
                                            title="Flip Horizontal"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.flipHorizontal()
                                              }
                                            >
                                              <em
                                                class="icon ni ni-swap"
                                                style={{ color: "white" }}
                                              ></em>{" "}
                                            </a>
                                          </Tooltip>

                                          <Tooltip
                                            title="Flip Vertical"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.flipVertical()
                                              }
                                            >
                                              <em
                                                class="icon ni ni-swap-v"
                                                style={{ color: "white" }}
                                              ></em>{" "}
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Adjust Brightness"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={
                                                this.toggleBrightnessRange
                                              }
                                            >
                                              <em
                                                className="icon ni ni-sun-fill"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          {showBrightnessRange && (
                                            <input
                                              type="range"
                                              min="0"
                                              max="200"
                                              value={brightness}
                                              onChange={
                                                this.handleBrightnessChange
                                              }
                                              style={{
                                                width: "15%",
                                                cursor: "pointer",
                                              }}
                                            />
                                          )}
                                          <Tooltip
                                            title="Reset Crop"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-danger"
                                              onClick={() =>
                                                this.handleResetCrop(15)
                                              }
                                            >
                                              <em
                                                class="icon ni ni-reload-alt"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                        </div>
                                        <div className="col-md-12 mt-3 p-0">
                                          <div className="example-alert">
                                            <div className="alert alert-light">
                                              <ul className="list">
                                                <li>
                                                  Double-click on the image to
                                                  drag it.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em class="icon ni ni-zoom-in"></em>
                                                  </b>{" "}
                                                  button to zoom in and the{" "}
                                                  <b>
                                                    <em class="icon ni ni-zoom-out"></em>
                                                  </b>{" "}
                                                  button to zoom out.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em className="icon ni ni-curve-up-left"></em>
                                                  </b>{" "}
                                                  icon to rotate left and the{" "}
                                                  <b>
                                                    <em className="icon ni ni-curve-up-right"></em>{" "}
                                                  </b>
                                                  icon to rotate right.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em className="icon ni ni-swap-v"></em>
                                                  </b>{" "}
                                                  icon to flip vertical and the{" "}
                                                  <b>
                                                    <em className="icon ni ni-swap"></em>{" "}
                                                  </b>
                                                  icon to flip horizontal.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em class="icon ni ni-reload-alt"></em>
                                                  </b>{" "}
                                                  button to reset the image and
                                                  crop box.
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <div className="example-alert">
                                      <div className="alert alert-light">
                                        <ul className="list">
                                          <li>
                                            Accepted Formats: PNG, JPEG, JPG
                                          </li>
                                          <li>Recommended Size: 5MB</li>
                                          <li>
                                            <b>Recommended Dimensions:</b>
                                            <br /> Maximum (W:300 x H:66)
                                            <br /> Minimum (W:200 x H:44)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="modal-footer"
                                style={{ justifyContent: "right" }}
                              >
                                <div class="d-flex justify-content-end">
                                  {this.state.signatory_button === true ? (
                                    <button
                                      className="btn btn-lg btn-primary"
                                      disabled
                                    >
                                      <em className="icon ni ni-swap"></em>{" "}
                                      Saving
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-lg btn-primary"
                                      onClick={() => this.submitSignatory()}
                                    >
                                      Save Signatory
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Add Signatory Form *****/}
                        {/***** Delete Signatory Alert *****/}
                        <div
                          class="modal fade"
                          tabindex="-1"
                          id="modalDeleteSignatory"
                        >
                          <div
                            class="modal-dialog modal-dialog-top"
                            role="document"
                          >
                            <div class="modal-content">
                              <a
                                href="#"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                              <div class="modal-header">
                                <h5 class="modal-title">Confirmation</h5>
                              </div>
                              <div class="modal-body">
                                {this.state.signatoryDeleteErrorMessage !==
                                "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-danger">
                                        <div className="alert-text">
                                          <h4>
                                            {
                                              this.state
                                                .signatoryDeleteErrorTitle
                                            }
                                          </h4>
                                          <p>
                                            {
                                              this.state
                                                .signatoryDeleteErrorMessage
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.signatoryDeleteSuccessMessage !==
                                "" ? (
                                  <div className="col-md-12">
                                    <div
                                      className="example-alert"
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <div className="alert alert-pro alert-success">
                                        <div className="alert-text">
                                          <h4>Success</h4>
                                          <p>
                                            {
                                              this.state
                                                .signatoryDeleteSuccessMessage
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                <p>
                                  Are you certain you wish to delete the
                                  profile? This action will result in the
                                  removal of the profile from all associated
                                  agreements.
                                </p>
                              </div>
                              <div
                                class="modal-footer"
                                style={{ justifyContent: "right" }}
                              >
                                <div class="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    class="btn btn-lg btn-light"
                                    style={{ marginRight: "15px" }}
                                    onClick={() => this.closeDeleteSignatory()}
                                  >
                                    No
                                  </button>
                                  {this.state.signatory_button_delete ===
                                  true ? (
                                    <button
                                      className="btn btn-lg btn-primary"
                                      disabled
                                    >
                                      <em className="icon ni ni-swap"></em>{" "}
                                      Deleting
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-lg btn-primary"
                                      onClick={() =>
                                        this.submitDeleteSignatory()
                                      }
                                    >
                                      Yes
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/***** Delete Signatory Alert *****/}
                        <div
                          class="modal fade flexibleModal"
                          tabindex="-1"
                          id="modalUpdateSignatory"
                        >
                          <div
                            class="modal-dialog modal-xl modal-dialog-top"
                            role="document"
                          >
                            <div class="modal-content">
                              <a
                                href="#"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <em class="icon ni ni-cross"></em>
                              </a>
                              <div class="modal-header">
                                <h5 class="modal-title">Update Profile</h5>
                              </div>
                              <div class="modal-body">
                                <div className="row g-gs">
                                  {this.state.signatorySettingErrorMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>
                                              {
                                                this.state
                                                  .signatorySettingErrorTitle
                                              }
                                            </h4>

                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  this.state
                                                    .signatorySettingErrorMessage,
                                              }}
                                            ></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.signatorySettingSuccessMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .signatorySettingSuccessMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Company Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_company"
                                          name="signatory_company"
                                          type="text"
                                          label="Company Name"
                                          value={this.state.signatory_company}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Legal Name <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_name"
                                          name="signatory_name"
                                          type="text"
                                          label=" Legal Name"
                                          value={this.state.signatory_name}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Title <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_title"
                                          name="signatory_title"
                                          type="text"
                                          label="Profile Title"
                                          value={this.state.signatory_title}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Email <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_email"
                                          name="signatory_email"
                                          type="text"
                                          label="Email"
                                          value={this.state.signatory_email}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 50, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Phone <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <PhoneInput
                                          required
                                          defaultCountry={"us"}
                                          inputProps={{
                                            name: "signatory_phone",
                                            id: "signatory_phone",
                                            maxLength: 20,
                                          }}
                                          style={{
                                            height: "56px",
                                          }}
                                          value={this.state.signatory_phone}
                                          placeholder={
                                            this.state.isLoading
                                              ? "Loading..."
                                              : "Enter the phone number"
                                          }
                                          onChange={(value) =>
                                            this.handleChange({
                                              target: {
                                                name: "signatory_phone",
                                                value,
                                              },
                                            })
                                          }
                                          helperText="Enter the phone number."
                                          label="Phone Number"
                                          ref={this.phoneInputRef}
                                          onFocus={this.handleFocus}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="form-label-group">
                                          <label className="form-label">
                                            Address <sup>*</sup>
                                            <span className="text-danger"></span>
                                          </label>
                                        </div>
                                        <div class="nk-block-head-content">
                                          <ul class="nk-block-tools gx-3">
                                            <li>
                                              <a
                                                onClick={this.handleToggle}
                                                class="text-primary fs-11"
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {this.state.toggleButtonText}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="form-control-group">
                                        {this.state.useGooglePlaces ? (
                                          <GooglePlacesAutocomplete
                                            apiKey={
                                              process.env
                                                .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                            }
                                            selectProps={{
                                              value: this.state
                                                .signatory_address
                                                ? {
                                                    label:
                                                      this.state
                                                        .signatory_address,
                                                    value:
                                                      this.state
                                                        .signatory_address,
                                                  }
                                                : null,
                                              onChange:
                                                this.handleAddressSelect,
                                              placeholder: this.state
                                                .signatory_address
                                                ? null
                                                : "Enter your address..",
                                              styles: {
                                                input: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                  height: "48px",
                                                }),
                                                option: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                }),
                                                singleValue: (provided) => ({
                                                  ...provided,
                                                  color: "black",
                                                }),
                                                menu: (provided) => ({
                                                  ...provided,
                                                  zIndex: 9,
                                                }),
                                              },
                                            }}
                                            className="googleLocation-autocomplete"
                                          />
                                        ) : (
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="signatory_address"
                                            name="signatory_address"
                                            value={this.state.signatory_address}
                                            placeholder="Enter your address"
                                            onChange={this.handleChange}
                                            style={{
                                              borderColor: this.state
                                                .errorAddress
                                                ? "red"
                                                : "initial",
                                              height: "56px",
                                              border: "1px solid #dbdfea",
                                              fontSize: "0.9375rem",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Apartment, Suite, Etc. <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_address2"
                                          name="signatory_address2"
                                          type="text"
                                          label="Apartment, Suite, Etc."
                                          value={this.state.signatory_address2}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          City <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_city"
                                          name="signatory_city"
                                          type="text"
                                          label="City"
                                          value={this.state.signatory_city}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          State <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_state"
                                          name="signatory_state"
                                          type="text"
                                          label="State"
                                          value={this.state.signatory_state}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Zip <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="signatory_zip"
                                          name="signatory_zip"
                                          type="text"
                                          label="Zip"
                                          value={this.state.signatory_zip}
                                          onChange={this.handleChange}
                                          error={this.state.errorvalidity}
                                          inputProps={{
                                            maxLength: 32, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Country <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <div className="form-group">
                                          <Select
                                            className="customStyle-country"
                                            options={this.countryOptions}
                                            value={{
                                              label:
                                                this.state.signatory_country,
                                              value:
                                                this.state.signatory_country,
                                            }}
                                            onChange={this.handleChangeCountry}
                                            placeholder="Select a country"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                minHeight: "56px",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Signature image of the signatory."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Upload Signature <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group signatory_upload">
                                        <div class="custom-file">
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="add_picture_update"
                                            name="add_picture_update"
                                            defaultValue={
                                              this.state.add_picture_update
                                            }
                                            accept=".png, .jpg, .jpeg"
                                            onChange={
                                              this.handleFileChangeUpdate
                                            }
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="add_picture_update"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.add_picture_existing &&
                                    !this.state.add_picture_update && (
                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "6px",
                                              }}
                                            >
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Previous Signature."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{ color: "#6576ff" }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                Previous Signature
                                                <span className="text-danger"></span>
                                              </label>
                                              {this.state.loading ? (
                                                <div
                                                  className="text-center"
                                                  style={{
                                                    color: "#6576ff",

                                                    backgroundColor:
                                                      "rgba(255, 255, 255, 0.7)",
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    marginBottom: "2px",
                                                  }}
                                                >
                                                  <div
                                                    className="spinner-border spinner-border-sm text-primary"
                                                    role="status"
                                                  >
                                                    <span className="sr-only">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <a
                                                  href="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                      loading: true,
                                                    });
                                                    setTimeout(() => {
                                                      fetch(
                                                        this.state
                                                          .add_picture_existing
                                                      )
                                                        .then((response) =>
                                                          response.blob()
                                                        )
                                                        .then((blob) => {
                                                          const url =
                                                            window.URL.createObjectURL(
                                                              blob
                                                            );
                                                          const a =
                                                            document.createElement(
                                                              "a"
                                                            );
                                                          a.href = url;
                                                          a.download =
                                                            "image.png";
                                                          document.body.appendChild(
                                                            a
                                                          );
                                                          a.click();
                                                          document.body.removeChild(
                                                            a
                                                          );
                                                          window.URL.revokeObjectURL(
                                                            url
                                                          );
                                                          this.setState({
                                                            loading: false,
                                                          });
                                                        })
                                                        .catch((error) => {
                                                          console.error(
                                                            "Download error:",
                                                            error
                                                          );
                                                          this.setState({
                                                            loading: false,
                                                          });
                                                        });
                                                    }, 500);
                                                  }}
                                                  style={{
                                                    color: "#6576ff",
                                                    backgroundColor:
                                                      "rgba(255, 255, 255, 0.7)",
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    marginBottom: "2px",
                                                  }}
                                                  title="Download Image"
                                                >
                                                  <em
                                                    className="icon ni ni-download"
                                                    style={{ fontSize: "18px" }}
                                                  ></em>
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                          <div className="form-control-group signatory_upload">
                                            <div
                                              style={{
                                                position: "relative",
                                                maxWidth: "60%",
                                                margin: "0 auto",
                                                display: "block",
                                              }}
                                            >
                                              <img
                                                src={
                                                  this.state
                                                    .add_picture_existing
                                                }
                                                alt="Previous Signature"
                                                style={{
                                                  maxWidth: "80%",
                                                  height: "auto",
                                                  display: "block",
                                                  margin: "0 auto",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  <div className="col-md-12">
                                    {this.state.add_picture_update && (
                                      <div style={{ margin: "15px 0 0 0" }}>
                                        <Cropper
                                          src={this.state.add_picture_update}
                                          style={{
                                            height: 400,
                                            width: "100%",
                                            filter: `brightness(${this.state.brightness}%)`,
                                          }}
                                          aspectRatio={300 / 66}
                                          guides={true}
                                          zoomable={true}
                                          crop={this.onCropUpdate}
                                          ref={this.cropperRef}
                                          rotatable={true}
                                          ready={this.handleReady}
                                        />
                                        <div
                                          style={{ margin: "10px 0 10px 0" }}
                                        >
                                          <p id="cropSize">No crop selected</p>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Tooltip
                                            title="Zoom In"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.handleZoomIn()
                                              }
                                            >
                                              <em
                                                className="icon ni ni-zoom-in"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Zoom Out"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.handleZoomOut()
                                              }
                                            >
                                              <em
                                                className="icon ni ni-zoom-out"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Rotate Left"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.rotateImage(-15)
                                              }
                                            >
                                              <em
                                                className="icon ni ni-curve-up-left"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Rotate Right"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.rotateImage(15)
                                              }
                                            >
                                              <em
                                                className="icon ni ni-curve-up-right"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>

                                          <Tooltip
                                            title="Flip Horizontal"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.flipHorizontal()
                                              }
                                            >
                                              <em
                                                class="icon ni ni-swap"
                                                style={{ color: "white" }}
                                              ></em>{" "}
                                            </a>
                                          </Tooltip>

                                          <Tooltip
                                            title="Flip Vertical"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={() =>
                                                this.flipVertical()
                                              }
                                            >
                                              <em
                                                class="icon ni ni-swap-v"
                                                style={{ color: "white" }}
                                              ></em>{" "}
                                            </a>
                                          </Tooltip>
                                          <Tooltip
                                            title="Adjust Brightness"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-primary"
                                              onClick={
                                                this.toggleBrightnessRange
                                              }
                                            >
                                              <em
                                                className="icon ni ni-sun-fill"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                          {showBrightnessRange && (
                                            <input
                                              type="range"
                                              min="0"
                                              max="200"
                                              value={brightness}
                                              onChange={
                                                this.handleBrightnessChange
                                              }
                                              style={{
                                                width: "15%",
                                                cursor: "pointer",
                                              }}
                                            />
                                          )}
                                          <Tooltip
                                            title="Reset Crop"
                                            placement="top"
                                          >
                                            <a
                                              className="btn btn-icon btn-sm btn-danger"
                                              onClick={() =>
                                                this.handleResetCrop(15)
                                              }
                                            >
                                              <em
                                                class="icon ni ni-reload-alt"
                                                style={{ color: "white" }}
                                              ></em>
                                            </a>
                                          </Tooltip>
                                        </div>
                                        <div className="col-md-12 mt-3 p-0">
                                          <div className="example-alert">
                                            <div className="alert alert-light">
                                              <ul className="list">
                                                <li>
                                                  Double-click on the image to
                                                  drag it.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em class="icon ni ni-zoom-in"></em>
                                                  </b>{" "}
                                                  button to zoom in and the{" "}
                                                  <b>
                                                    <em class="icon ni ni-zoom-out"></em>
                                                  </b>{" "}
                                                  button to zoom out.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em className="icon ni ni-curve-up-left"></em>
                                                  </b>{" "}
                                                  icon to rotate left and the{" "}
                                                  <b>
                                                    <em className="icon ni ni-curve-up-right"></em>{" "}
                                                  </b>
                                                  icon to rotate right.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em className="icon ni ni-swap-v"></em>
                                                  </b>{" "}
                                                  icon to flip vertical and the{" "}
                                                  <b>
                                                    <em className="icon ni ni-swap"></em>{" "}
                                                  </b>
                                                  icon to flip horizontal.
                                                </li>
                                                <li>
                                                  Use the{" "}
                                                  <b>
                                                    <em class="icon ni ni-reload-alt"></em>
                                                  </b>{" "}
                                                  button to reset the image and
                                                  crop box.
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <div className="example-alert">
                                      <div className="alert alert-light">
                                        <ul className="list">
                                          <li>
                                            Accepted Formats: PNG, JPEG, JPG
                                          </li>
                                          <li>Recommended Size: 5MB</li>
                                          <li>
                                            <b>Recommended Dimensions:</b>
                                            <br /> Maximum (W:300 x H:66)
                                            <br /> Minimum (W:200 x H:44)
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="modal-footer"
                                style={{ justifyContent: "right" }}
                              >
                                <div class="d-flex justify-content-end">
                                  {this.state.signatory_button === true ? (
                                    <button
                                      className="btn btn-lg btn-primary"
                                      disabled
                                    >
                                      <em className="icon ni ni-swap"></em>{" "}
                                      Updating
                                      <div
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-lg btn-primary"
                                      onClick={() =>
                                        this.submitUpdateSignatory()
                                      }
                                    >
                                      Update Signatory
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* UPDATE MODAL START */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upgrades);
