import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServicesTypes_single,
  editServiceType_single,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR, AGENT_SERVER_URL } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
//import { Editor } from "@tinymce/tinymce-react";
import Tooltip from "@material-ui/core/Tooltip";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class EditServiceType extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      disabled: false,
      service_type_id: "",
      product_title: "",
      product_desc: "",
      status: "",
      isEditorReady: false,
      isDefault: "",
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  async componentDidMount() {
    this.setState({
      errorMessage: "",
      successMessage: "",
      service_type_id: "",
      tableLoader: true,
    });
    let service_type_id = this.props.props.match.params.id;
    const servicesResponce = await listServicesTypes_single(
      auth.getAccount(),
      auth.getToken(),
      service_type_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        isEditorReady: true,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableLoader: false,
        service_type_id: servicesResponce.data.data.product_id,
        product_title: servicesResponce.data.data.product_title,
        product_desc: servicesResponce.data.data.product_desc,
        status: servicesResponce.data.data.status,
        isEditorReady: true,
        isDefault: servicesResponce.data.data.isDefault,
      });
    } else {
      this.setState({
        isEditorReady: true,
      });
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleEditorProductDesc = (event, editor) => {
    const content = editor.getData();
    this.setState({
      product_desc: content,
    });
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const { product_title, product_desc, status, service_type_id, isDefault } = this.state;

    let errorMessage = "";

    // Validation errors take priority
    if (product_title === "") {
      errorMessage = "Product title is required.";
    } else if (product_desc === "") {
      errorMessage = "Product description is required.";
    } else if (status === "") {
      errorMessage = "Status is required.";
    }

    if (errorMessage) {
      this.setState({ errorMessage });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 5000);

      return;
    }

    if (isDefault === 1 && status === "0") {
      this.setState({
        errorMessage: "Default services must stay active. Remove it as default to set it inactive.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 5000);

      return;
    }

    this.setState({
      errorMessage: "",
      disabled: true,
    });

      const saveResponce = await editServiceType_single(
        auth.getAccount(),
        auth.getToken(),
        product_title,
        product_desc,
        status,
        service_type_id
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while updating the Service Type.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Service Type updated successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/services/service_type");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    
  };

  handleEditorDesc = (updatedContent) => {
    //console.log("updatedContent: ", updatedContent);
    this.setState({ product_desc: updatedContent });
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(AGENT_SERVER_URL +"agents/editor_token_url", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            request_type: "ckeditor-tokenUrl",
            user: auth.getAccount()
          })
        });
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error('Failed to fetch token:', error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() =>
                      this.addMenuHistory("/services/service_type/")
                    }
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Service Type
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Service Type
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Update Service Type
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="product_title"
                                name="product_title"
                                type="text"
                                label="Service Title"
                                value={this.state.product_title}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="product_desc"
                                name="product_desc"
                                type="text"
                                label="Product  Desc"
                                value={this.state.product_desc}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div> */}
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="status"
                                name="status"
                                select
                                label="Select Status"
                                value={this.state.status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Service Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="0" value="0">
                                  Inactive
                                </option>
                                <option key="1" value="1">
                                  Active
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <h6>Product Description <span>*</span></h6>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                            {this.state.isEditorReady ? (<>
                              <RichTextEditorComponent
                                    agreementContent={this.state.product_desc}
                                    onContentChange={
                                      this.handleEditorDesc
                                    }
                                  />
                            </>) : null }
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditServiceType);
